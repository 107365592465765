import React from "react";
import HeaderView from "@/views/Header";
import { TabPanel, TabsContent, Tag, Tags } from "@/components/Core";
import usePage from "@/modules/hooks/usePage";
import { useQuery } from "react-query";
import { entitiesApiService } from "@/api/services";
import useTabs from "@/modules/hooks/useTabs";
import { BrandSchema, CatalogMenuSchema } from "@/api/services/entities/schemas";
import CatalogMenuEntity from "@/api/services/entities/entities/CatalogMenu.entity";
import { EntityFetcher } from "@/components/Controls";
import { BrandEntity } from "@/api/services/entities/entities";
import { EntitiesQueryParams } from "@/api/services/entities";
import { Config } from "@/modules/constants";

export type HeaderProps = Partial<{ handleToggleCatalog: any }>;

const PopupMenu: React.FunctionComponent<HeaderProps> = ({ handleToggleCatalog }: HeaderProps) => {
    const { page } = usePage();
    const { handleChangeTab, activeTab } = useTabs<string[]>({ initialTab: "0" });

    const config = page?.config;
    const catalogs = config?.catalogs;

    const menuEtypeId = catalogs?.menuEtypeId;
    const siteAtypeId = catalogs?.siteAtypeId;

    const { data: catalogMenu } = useQuery(["catalog-menu", menuEtypeId, siteAtypeId], () => {
        if (!menuEtypeId) return null;
        const args: EntitiesQueryParams = {
            et_id: menuEtypeId,
        };
        if (siteAtypeId) {
            args[siteAtypeId] = Config.SITE_NAME;
        }
        return entitiesApiService.getTransformedEntities<CatalogMenuSchema, CatalogMenuEntity>(
            CatalogMenuEntity,
            args,
            "src/components/Header/PopupMenu/index.tsx"
        );
    });

    const handleBack = (e) => {
        handleChangeTab(e, "");
    };

    return (
        <HeaderView.PopupMenu onToggleCatalog={handleToggleCatalog}>
            <HeaderView.PopupMenu.Col position="left" wrap>
                <HeaderView.PopupMenu.Mobile />
                <HeaderView.PopupMenu.Categories>
                    {catalogMenu?.items.map((menuItem, i) => (
                        <HeaderView.PopupMenu.Categories.Category
                            key={menuItem.id}
                            active={activeTab === i.toString()}
                            onSelect={(e) => handleChangeTab(e, i.toString())}
                        >
                            {menuItem.title}
                        </HeaderView.PopupMenu.Categories.Category>
                    ))}
                </HeaderView.PopupMenu.Categories>
            </HeaderView.PopupMenu.Col>
            <HeaderView.PopupMenu.Col position="right">
                <TabsContent>
                    {catalogMenu?.items.map((menuItem, i) => {
                        const title = menuItem.title;
                        const subcats = Object.entries(menuItem.subcats).map((el) => ({
                            groupNumber: el[0],
                            subcats: el[1],
                        }));
                        const brandsIds = menuItem.brandsIds;
                        const tags = menuItem.tags;
                        const bannerId = menuItem.bannerId;

                        return (
                            <TabPanel key={menuItem.id} id={i.toString()} activeTab={activeTab}>
                                <HeaderView.PopupMenu.Menu active={activeTab === i.toString()}>
                                    <HeaderView.PopupMenu.Menu.Top heading={title} onBack={handleBack} />
                                    <HeaderView.PopupMenu.Menu.Cols>
                                        {subcats.map((col) => (
                                            <HeaderView.PopupMenu.Menu.Col key={col.groupNumber}>
                                                <HeaderView.PopupMenu.Menu.Subcats>
                                                    {col.subcats.map((cat) => (
                                                        <HeaderView.PopupMenu.Menu.Item
                                                            key={cat.title}
                                                            href={cat.url}
                                                            type={cat.isHead ? "name" : "link"}
                                                        >
                                                            {cat.title}
                                                        </HeaderView.PopupMenu.Menu.Item>
                                                    ))}
                                                </HeaderView.PopupMenu.Menu.Subcats>
                                            </HeaderView.PopupMenu.Menu.Col>
                                        ))}

                                        {/* <HeaderView.PopupMenu.Menu.ShowAll href="">
                                                Смотреть всю одежду
                                            </HeaderView.PopupMenu.Menu.ShowAll> */}
                                        <HeaderView.PopupMenu.Menu.Col size={12}>
                                            <EntityFetcher<BrandSchema, BrandEntity>
                                                Model={BrandEntity}
                                                queryKey="brands"
                                                idsList={brandsIds}
                                            >
                                                {({ items }) => (
                                                    <HeaderView.PopupMenu.Menu.Brands>
                                                        {items.map((brand) => (
                                                            <HeaderView.PopupMenu.Menu.Brands.Item
                                                                key={brand.id}
                                                                href={brand.catalogUrl}
                                                                image={brand.image}
                                                            />
                                                        ))}
                                                    </HeaderView.PopupMenu.Menu.Brands>
                                                )}
                                            </EntityFetcher>
                                            <EntityFetcher<BrandSchema, BrandEntity>
                                                Model={BrandEntity}
                                                queryKey="banner"
                                                idsList={bannerId ? [bannerId] : []}
                                            >
                                                {({ items }) => (
                                                    <>
                                                        {items.map((item) => (
                                                            <HeaderView.PopupMenu.Menu.Banner
                                                                key={item.id}
                                                                href={item.catalogUrl}
                                                                image={item.background}
                                                                subImage={item.image}
                                                                title={item.title}
                                                            />
                                                        ))}
                                                    </>
                                                )}
                                            </EntityFetcher>
                                            <Tags>
                                                {tags
                                                    .filter((tag) => !!tag[0]?.value && !!tag[1]?.value)
                                                    .map((tag) => (
                                                        <Tag key={tag[0].id} href={tag[1].value}>
                                                            {tag[0].value}
                                                        </Tag>
                                                    ))}
                                            </Tags>
                                        </HeaderView.PopupMenu.Menu.Col>
                                    </HeaderView.PopupMenu.Menu.Cols>
                                </HeaderView.PopupMenu.Menu>
                            </TabPanel>
                        );
                    })}
                </TabsContent>
            </HeaderView.PopupMenu.Col>
        </HeaderView.PopupMenu>
    );
};

export default PopupMenu;
