import React from "react";

// export type BrandProps = React.ImgHTMLAttributes<HTMLImageElement>;
//
// const Brand: React.FunctionComponent<BrandProps> = (props: BrandProps) => {
//     return (
//         <div className="catcard__brand">
//             <img className="catcard__brand-img" {...props} />
//         </div>
//     );
// };

export type BrandProps = React.ImgHTMLAttributes<HTMLImageElement> & {
    brandTitle?: string;
};

const Brand: React.FunctionComponent<BrandProps> = ({ brandTitle, ...props }: BrandProps) => {
    return (
        <div className="catcard__brand">
            {/*<img className="catcard__brand-img" {...props} alt={brandTitle} />*/}
            {brandTitle && <span className="catcard__brand-title">{brandTitle}</span>}
        </div>
    );
};

export default Brand;
