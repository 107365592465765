import { entitiesApiService } from "@/api/services";
import { Entity, TypeSchemaAttribute } from "@/api/services/entities/models";
import { ChangeFilter } from "@/modules/hooks/useFilters";
import { isEveryTrue } from "@/modules/utils";
import { ReactNode, useCallback, useState } from "react";
import { useQuery } from "react-query";

type FilterType = "switchbox" | "list" | "none";
type RenderFilterProps = {
    toggle: boolean;
    name: string;
    expansion: boolean;
    items?: Entity[];
    handleChange(e: React.ChangeEvent): void;
    handleToggle(): void;
    type: FilterType;
    skip: boolean;
    isLoading: boolean;
};

export type FilterProps = {
    name: string;
    schemaAttribute?: TypeSchemaAttribute;
    children: ((props: RenderFilterProps) => ReactNode) | ReactNode;
    onChange: ChangeFilter;
};

const Filter = ({ name, schemaAttribute, children, onChange }: FilterProps) => {
    const [toggle, setToggled] = useState<boolean>(false);
    const valueTypeId = schemaAttribute?.items[0].vtype_id ?? null;
    const { data: bind, isFetching: vBindLoadingStatus } = useQuery(
        ["vbind", valueTypeId],
        () => entitiesApiService.getValueTypeBind(valueTypeId, "src/components/Controls/Filter/index.tsx"),
        { enabled: !!valueTypeId }
    );

    const refEntityTypeId = schemaAttribute?.items[0].restrictions?.et_id ?? null;
    const { data: items, isFetching: itemsLoadingStatus } = useQuery(
        ["items", refEntityTypeId],
        () => entitiesApiService.getEntities({ et_id: refEntityTypeId }, "src/components/Controls/Filter/index.tsx"),
        {
            enabled: !!refEntityTypeId,
        }
    );

    const handleChange = useCallback(
        (e) => {
            const { value, checked } = e.target;
            onChange(schemaAttribute?.name || name, value, checked ? "add" : "remove");
        },
        [name, schemaAttribute, onChange]
    );

    const handleToggle = () => {
        setToggled((prev) => !prev);
    };

    const getFilterType = (): FilterType => {
        if (refEntityTypeId) return "list";
        if (bind?.name === "checkbox") return "switchbox";
        return "none";
    };

    const props: RenderFilterProps = {
        toggle,
        name: schemaAttribute?.name || name,
        expansion: !!refEntityTypeId,
        items: items?.items,
        type: getFilterType(),
        handleChange,
        handleToggle,
        skip: schemaAttribute ? !schemaAttribute.isActive : false,
        isLoading: isEveryTrue(vBindLoadingStatus, itemsLoadingStatus),
    };
    return typeof children === "function" ? children(props) : children;
};

export default Filter;
