import { entitiesApiService } from "@/api/services";
import { EntityModel } from "@/api/services/entities/entities";
import { ClassConstructor } from "class-transformer";
import { ReactNode } from "react";
import { useQuery } from "react-query";

type RenderEntityFetcherProps<Schema, Model extends EntityModel<Schema>> = {
    items: Model[];
    count: number;
};

export type EntityFetcherProps<Schema, Model extends EntityModel<Schema>> = {
    queryKey: string;
    entityTypeId?: number;
    idsList: string[] | number[];
    Model: ClassConstructor<Model>;
    children: ((props: RenderEntityFetcherProps<Schema, Model>) => ReactNode) | ReactNode;
};

const EntityFetcher = <S, M extends EntityModel<S>>({
    queryKey,
    entityTypeId,
    idsList,
    Model,
    children,
}: EntityFetcherProps<S, M>) => {
    const { data } = useQuery(
        [queryKey, entityTypeId, idsList],
        () => {
            const params = {
                et_id: entityTypeId,
                ids_list: idsList.join(","),
            };
            return entitiesApiService.getTransformedEntities<S, M>(
                Model,
                params,
                "src/components/Controls/EntityFetcher/index.tsx"
            );
        },
        // () =>
        //     entitiesApiService.getTransformedEntities<S, M>(Model, {
        //         et_id: entityTypeId,
        //         ids_list: idsList.join(","),
        //     }),
        { enabled: idsList.length > 0 }
    );

    const props: RenderEntityFetcherProps<S, M> = {
        items: data?.items || [],
        count: data?.count || 0,
    };

    return typeof children === "function" ? children(props) : children;
};

export default EntityFetcher;
